<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px ;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">

					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<div class="z_form_item">
								<div class="z_form_item_label">选择人员：</div>
								<div class="z_form_item_value">
									<el-select v-model="form.staff_id" filterable placeholder="请选择">
										<el-option v-for="item in personerList" :key="item.staff_id" :label="item.staff_name" :value="item.staff_id"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">

					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="closeDialog">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['parentPageParams'],
		data() {
			return {
				personerList: [],
				form: {
					staff_id: ''
				},
				rules: null,
				btnSaveLoading: false,
			}
		},
		created: function() {
			_this = this;
			_this.api_getPersonerList();
		},
		methods: {
			/* ==------>>>:获取人员列表，更具服务id
			 *  autor:郑凯
			==------------==*/
			api_getPersonerList() {
				var apiParams = {
					service_item_id: _this.parentPageParams.service_item_id,
					residence_id: _this.parentPageParams.commiunity_id
				};
				_this._getApi('/wy/staff/getStaffListByServiceItemId', apiParams).then((res) => {
					if (res.code == 1) {
						_this.personerList = res.data;
					} else {
						console.log(res)
					}
				}).catch((err) => {
					console.log(err)
				})
			},
			/* ==------>>>:关闭弹窗
			 *  autor:郑凯
			==------------==*/
			closeDialog() {
				_this.$emit('childrenEvent', {
					type: 'close'
				});
			},
			/* ==------>>>: 提交保存
			 *  autor:郑凯
			==------------==*/
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				params.id = _this.parentPageParams.id;
				_this._postApi('/wy/userService/setStaff', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'success'
						});
						_this.$message({
							message: '添加成功！',
							type: 'success',
							duration: 1000,
							onClose: function() {}
						});

					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>

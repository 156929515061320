<style scoped>
	.sub-unit_box {width: 100%;height: 100%;display: flex;flex-direction: column;}
	.sub-unit_box_body {flex: 1;}
	.body_box {height: 100%;padding: 0;}
	.sub-unit_box_header{display: flex;flex-direction: row;padding: 10px 5px;background-color: #F1F1F1;}
	.sub-unit_box_footer{text-align: right;padding: 5px;padding-bottom: 10px;}
	.header_item{padding-right: 20px;}
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{background-color: #67CD80 !important; color: #fff;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	/* 搜索框按钮 */
	.header_item .el-input-group__append button.el-button{background-color: rgb(230,162,60);color: #FFF;}
</style>
<style>
	/* 搜索框按钮 */
	.header_item .el-input-group__append{background-color: rgb(230,162,60);}
</style>
<template>
	<div class="sub-unit_box">
		<div class="sub-unit_box_header">
			<div class="header_item">
				<el-input placeholder="请输入工单号" v-model="searchKey" class="input-with-select">
					<el-button slot="append" type="warning" icon="el-icon-search" @click="queryBySearchKey"></el-button>
				</el-input>
			</div>
			<div class="header_item">
				<el-select v-model="status" placeholder="请选择" @change="statusSelectChange">
					<el-option label="全部" value="0">全部</el-option>
					<el-option label="待处理" value="1">待处理</el-option>
					<el-option label="处理完成" value="2">处理完成</el-option>
					<el-option label="已取消" value="3">已取消</el-option>
				</el-select>
			</div>
			<div class="header_item">
				<el-select v-model="service_item_id" placeholder="请选择" @change="statusSelectServiceChange">
					<el-option label="选择服务项目:全部" value="0">全部</el-option>
					<el-option :label="item.name" :value="item.service_item_id" v-for="item in serviceOpetions">{{item.name}}</el-option>
				</el-select>
			</div>
			<el-button type="warning" size="small" @click="openDesignated">指派人员<i class="el-icon-circle-plus-outline"></i></el-button>
			<el-button type="danger" size="small" @click="refresh">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="sub-unit_box_body">
			<div class="body_box" id="body_box">
				<el-table :data="tableData" :height="tableHeight" border style="width: 100%" v-loading="tableLoading"
				 highlight-current-row @row-click="rowClick">
					<el-table-column prop="itemInfo.item_name" label="项目" width="130"></el-table-column>
					<el-table-column prop="roomInfo.room_code" label="报事人户号" width="100"></el-table-column>
					<el-table-column prop="ownerInfo.owner_name" label="报事人姓名" width="100"></el-table-column>
					<el-table-column prop="ownerInfo.owner_phone" label="联系方式" width="120"></el-table-column>
					<el-table-column prop="service_num" label="工单号" show-overflow-tooltip></el-table-column>
					<el-table-column prop="create_time" label="报事时间" show-overflow-tooltip></el-table-column>
					<el-table-column prop="status_text" label="处理状态"></el-table-column>
					<el-table-column prop="" label="委派处理人员">
						<template slot-scope="scope">
							<span v-if="scope.row.staffInfo">{{scope.row.staffInfo.staff_name}}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="sub-unit_box_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>
		<!-- 委派工作人员 -->
		<el-dialog custom-class="my_dialog" width="30%" top="none" title="委派工作人员" :visible.sync="dialogShowDesignated"
		 destroy-on-close>
			<Designated v-if="dialogShowDesignated" :parentPageParams="designatedParams" v-on:childrenEvent="handlerChildrenEventByDesignated"></Designated>
		</el-dialog>
	</div>
</template>

<script>
	import Designated from './Designated.vue';
	var _this;
	export default {
		components: {
			Designated: Designated
		},
		props: ['subPageParams'],
		data() {
			return {
				tableLoading: true,
				tableHeight: 0,
				searchKey: '',
				status: '0',
				service_item_id: '0',
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				tableData: [],
				selectTableRow: null,
				dialogShowDesignated: false,
				designatedParams: null, //-------------------->>>:页面参数，指定人员
				serviceOpetions: []
			}
		},
		created: function() {
			_this = this;
			_this.apiGetServiceList();
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById('body_box').offsetHeight;
				_this.api_getTableData();
			})
		},
		methods: {
			/** ->  获取服务项目选择列表
			author:郑凯 
			create_time:2021-3-9 11:10:16 
			*/
			apiGetServiceList() {
				_this._getApi('/wy/propertyServiceItem/getBsItemSelect', {

				}).then((res) => {
					if (res.code == 1) {
						_this.serviceOpetions = res.data;
					} else {
						console.log(res)
					}
				}).catch((err) => {
					console.log(err)
				})
			},

			/* ==------>>>: 选择状态查询
			 *  autor:郑凯
			==------------==*/
			statusSelectChange(val) {
				_this.tableLoading = true;
				_this.pageNumber = 1;
				_this.api_getTableData();
			},
			/* ==------>>>: 工单号搜索查询
			 *  autor:郑凯
			==------------==*/
			queryBySearchKey() {
				_this.tableLoading = true;
				_this.pageNumber = 1;
				_this.api_getTableData();
			},

			// 选择服务项目
			statusSelectServiceChange(val) {
				_this.tableLoading = true;
				_this.pageNumber = 1;
				_this.api_getTableData();
			},

			/* ---------------
			 *  获取表格数据
			----------------*/
			api_getTableData() {
				if(!_this.subPageParams.id){
					_this.tableLoading = false;
					return;
				}
				var apiParams = {
					page: _this.pageNumber,
					limit: _this.pageSize,
					residence_id: _this.subPageParams.id,
					status: _this.status,
					service_num: _this.searchKey,
					service_item_id: _this.service_item_id
				};
				_this._postApi('/wy/userService/getList', apiParams).then((res) => {
					if (res.code == 1) {
						_this.tableData = res.data.list;
						_this.pageTotal = res.data.count;
						_this.tableLoading = false;
					} else {
						console.log(res)
					}
				}).catch((err) => {
					console.log(err)
				})
			},
			/* * 切换分页 */
			handleCurrentChange(val) {
				_this.pageNumber = val;
				_this.api_getTableData();
			},
			/* ---------------
			 *  选择某行
			----------------*/
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},
			/* ==------>>>:指定人员 
			 *  autor:郑凯
			==------------==*/
			openDesignated() {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择工单事件进行指派人员,点击事件即可选中', '提示', {
						confirmButtonText: '确定',
						callback: function(action) {}
					});
					return;
				}
				_this.designatedParams = baseData;
				_this.designatedParams.commiunity_id = _this.subPageParams.id;
				_this.dialogShowDesignated = true;
			},
			/* ---------------
			 *  刷新
			----------------*/
			refresh() {
				_this.tableLoading = true;
				_this.selectTableRow = null;
				_this.$nextTick(function() {
					_this.tableHeight = document.getElementById('body_box').offsetHeight;
					_this.api_getTableData();
				})
			},
			/* ---------------
			 *  dialog消息事件
			----------------*/
			handlerChildrenEventByDesignated(e) {
				switch (e.type) {
					case 'close':
						_this.dialogShowDesignated = false;
						break;
					case 'success':
						_this.refresh();
						_this.dialogShowDesignated = false;
						break;
				}
			}
		}
	}
</script>
